import { useMemo } from 'react';
import { TPA_EXPERIMENTS } from '@wix/pricing-plans-common/experiments';
import { useSettings } from '@wix/tpa-settings/react';
import { useEnvironment, useExperiments } from '@wix/yoshi-flow-editor';
import { useSettingsParams } from './useSettingsParams';

export const useShouldUseMobileSettings = () => {
  const { experiments } = useExperiments();
  const { isCssPerBreakpoint } = useEnvironment();
  const settings = useSettings();
  const settingsParams = useSettingsParams();

  return useMemo(
    () =>
      settings.get(settingsParams.useMobileSettingsInStudio) ||
      !isCssPerBreakpoint ||
      !experiments.enabled(TPA_EXPERIMENTS.STUDIO_MOBILE_SETTINGS_MIGRATION),
    [experiments, isCssPerBreakpoint, settings, settingsParams.useMobileSettingsInStudio],
  );
};
